import React from 'react'

import Layout from '../components/layout'
import ButtonLink from '../components/ButtonLink'
import { H1 } from '../components/Headlines'
import { Hero } from '../components/Hero'
import Section from '../components/Section'
import { GallerySection } from '../components/GallerySection'
import { TestDrive } from '../components/TestDrive'
import SimpleSlider from '../components/Slider'

import { ImageBox } from '../components/ImageBox'

import NeverStopImproving from '../images/c-class-limousine/highlights/never-stop-improving.jpeg'
import SportyDesign from '../images/c-class-limousine/highlights/sporty-design.jpeg'
import DigitalCockpit from '../images/c-class-limousine/highlights/digital-cockpit.jpeg'
import IntelligentDrive from '../images/c-class-limousine/highlights/intelligent-drive.jpeg'
import Energizing from '../images/c-class-limousine/highlights/energizing.jpeg'

import HeaderImage from '../images/c-class-limousine/header-c-class-limousine.jpeg'

import InterieurImage from '../images/c-class-limousine/gallery/interieur-teaser-c-class-limousine.jpeg'
import ExterieurImage from '../images/c-class-limousine/gallery/exterieur-teaser-c-class-limousine.jpeg'

import InterieurImageGallery1 from '../images/c-class-limousine/gallery/gallery1-interieur-c-class-limousine.jpeg'
import InterieurImageGallery2 from '../images/c-class-limousine/gallery/gallery2-interieur-c-class-limousine.jpeg'
import InterieurImageGallery3 from '../images/c-class-limousine/gallery/gallery3-interieur-c-class-limousine.jpeg'
import InterieurImageGallery4 from '../images/c-class-limousine/gallery/gallery4-interieur-c-class-limousine.jpeg'

import ExterieurImageGallery1 from '../images/c-class-limousine/gallery/gallery1-exterieur-c-class-limousine.jpeg'
import ExterieurImageGallery2 from '../images/c-class-limousine/gallery/gallery2-exterieur-c-class-limousine.jpeg'
import ExterieurImageGallery3 from '../images/c-class-limousine/gallery/gallery3-exterieur-c-class-limousine.jpeg'

const GalleryImagesInterieurSrc = [
  InterieurImageGallery1,
  InterieurImageGallery2,
  InterieurImageGallery3,
  InterieurImageGallery4,
]

const GalleryImagesExterieurSrc = [
  ExterieurImageGallery1,
  ExterieurImageGallery2,
  ExterieurImageGallery3,
]

const CClassLimousine = () => (
  <Layout>
    <Hero bgImage={HeaderImage} />
    <Section align="center">
      <H1>Never stop improving. Die neue C-Klasse Limousine.</H1>
      <p>
        Die C-Klasse, die erfolgreichste Baureihe von Mercedes-Benz, startet mit
        umfangreichen Modifikationen in ihr fünftes Produktionsjahr. Stilvoll
        überarbeitet wurden Exterieur- und Interieurdesign. Ganz neu ist die
        elektronische Architektur. Auf Wunsch gibt es ein volldigitales
        Instrumenten-Display und Multimediasysteme mit maßgeschneiderten
        Informations- und Musikangeboten. Auch die Assistenzsysteme profitieren
        von der neuen E-Architektur und sind jetzt auf dem Stand der S-Klasse.
      </p>
      <ButtonLink to="/probefahrt/">weiter zur Terminauswahl</ButtonLink>
    </Section>
    <GallerySection
      InterieurImageSrc={InterieurImage}
      ExterieurImageSrc={ExterieurImage}
      GalleryImagesInterieur={GalleryImagesInterieurSrc}
      GalleryImagesExterieur={GalleryImagesExterieurSrc}
    />
    <Section align="default" variant="grey">
      <H1>
        Sich niemals auszuruhen, sondern Innovationskraft immer wieder neu zu
        beweisen.
      </H1>
      <SimpleSlider>
        <ImageBox
          imagesrc={NeverStopImproving}
          title="Never stop improving"
          headline="Never stop improving"
          description=""
        />
        <ImageBox
          imagesrc={SportyDesign}
          title="Sportliches Design"
          headline="Sportliches Design bis in die Heckleuchten."
          description="Vorwärtsdrang steht der neuen C-Klasse Limousine ins Gesicht geschrieben: Die Front strahlt Dynamik und Modernität aus. Die Stoßfänger sind neu gestaltet, je nach Ausstattung glänzen edle Chromelemente und faszinieren neue MULTIBEAM LED Scheinwerfer. Die neuen Heckleuchten sind besonders filigran gestaltet: Sie betonen die Breite des Fahrzeugs und sorgen für einen noch kraftvolleren Abschluss."
        />
        <ImageBox
          imagesrc={DigitalCockpit}
          title="Digitales Cockpit"
          headline="Neues digitales Bedien- und Anzeigeerlebnis."
          description="Die neue C-Klasse bedienen Sie so intuitiv und einfach wie ein Smartphone. Das volldigitale Kombiinstrument ermöglicht eine ganz neue Visualisierung von Fahrdaten. Für Ihr persönliches Fahrerlebnis stehen drei aufwendig animierte Stile zur Wahl. In Kombination mit dem neuen 10,25 Zoll großen hochauflösenden Display des Multimediasystems, den neuen Touch Control Buttons am Lenkrad und der Sprachsteuerung LINGUATRONIC bedeutet das höchsten Bedienkomfort."
        />
        <ImageBox
          imagesrc={IntelligentDrive}
          title="Mercedes-Benz Intelligent Drive"
          headline="Die neue Generation Mercedes-Benz Intelligent Drive"
          description="Schnell, präzise, wachsam: Hochmoderne Mercedes-Benz Intelligent Drive Assistenzsysteme entlasten wirkungsvoller denn je. Ob Wiederanfahren im Stau, situationsgerechte Bremskraftunterstützung bei erkannter Kollisionsgefahr oder Distanzeinhaltung auf der Autobahn bei bis zu 210 km/h: Dabei kann die neue C-Klasse den Fahrer schon heute unterstützen. Einen zuverlässigeren und aufmerksameren Partner können Sie sich unterwegs kaum wünschen."
        />
        <ImageBox
          imagesrc={Energizing}
          title="ENERGIZING Komfortsteuerung."
          headline="Kraft tanken mit der ENERGIZING Komfortsteuerung."
          description="Die neue C-Klasse begeistert nicht nur mit ihrer Kraft, sie kann auch das Wohlbefinden des Fahrers fördern: mit der optionalen ENERGIZING Komfortsteuerung. Lichter, Klänge und Düfte können auf Tastendruck miteinander kombiniert werden, um die Insassen zu entspannen oder zu beleben. Die inkludierte Ambientebeleuchtung mit bis zu 64 Farben inszeniert den Innenraum im schönsten Licht."
        />
      </SimpleSlider>
    </Section>
    <TestDrive />
  </Layout>
)

export default CClassLimousine
